export function ScoutLogoDefault(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      viewBox="0 0 810 810"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#ff3d87"
        d="M 465.652344 438.003906 L 451.152344 438.003906 L 587.359375 182.871094 L 404.558594 0.0507812 L 221.753906 182.851562 L 357.964844 437.984375 L 343.460938 437.984375 C 248.910156 345.6875 92.535156 312.054688 -1.671875 406.277344 L 201.445312 609.390625 C 251.855469 559 320.894531 527.25 397.347656 525.363281 L 299.894531 707.882812 L 404.515625 812.503906 L 509.140625 707.882812 L 411.6875 525.363281 C 488.140625 527.230469 557.199219 559 607.589844 609.390625 L 810.703125 406.277344 C 716.480469 312.054688 560.121094 345.6875 465.574219 437.984375 Z M 465.652344 438.003906"
      />
    </svg>
  );
}
