import * as path from "path";
import { Geography, LeadStatus, RoundRole, Sector } from "@prisma/client";
import cheerio from "cheerio";
import { clsx, type ClassValue } from "clsx";
import fetch from "node-fetch";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const calculateDaysBetween = (timestamp: string | undefined): number => {
  if (!timestamp) return 0;
  const givenDate = new Date(timestamp);
  const today = new Date();

  // Resetting hours, minutes, seconds, and milliseconds for accurate day difference
  today.setHours(0, 0, 0, 0);
  givenDate.setHours(0, 0, 0, 0);

  const differenceInMilliseconds = today.getTime() - givenDate.getTime();
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return Math.round(differenceInDays); // Use Math.floor() or Math.ceil() as needed
};

export function extractUUID(inputString: string): string | null {
  // Regular expression to match a UUID
  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  const match = inputString.match(uuidRegex);

  // Return the matched UUID or null if no match is found
  return match ? match[0] : null;
}

export async function fetchWebsiteIconUrl(websiteUrl: string): Promise<string | null> {
  try {
    console.log("Fetching website icon:", websiteUrl);
    // Fetch the HTML content of the website
    const response = await fetch(websiteUrl);
    const html = await response.text();

    // Load the HTML into cheerio
    const $ = cheerio.load(html);

    // Find the icon link elements
    const iconLinkElements = $(
      'link[rel~="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]'
    );

    // Extract the href attribute (URL) of the first icon found
    if (iconLinkElements.length > 0) {
      const iconUrl = iconLinkElements.first().attr("href");
      // Convert relative URL to absolute if necessary
      return new URL(iconUrl!, websiteUrl).href;
    }

    return null;
  } catch (error) {
    console.error("Error fetching website icon:", error);
    throw error;
  }
}

export function convertGeographyValuesToStringArray(enumValues: Geography[] | undefined): string[] {
  if (!enumValues) return [];
  return enumValues
    .map((enumValue) => {
      // Find the key in the Geography enum that matches the current enum value.
      const key = Object.keys(Geography).find(
        (key) => Geography[key as keyof typeof Geography] === enumValue
      );
      if (key !== undefined) {
        return key;
      } else {
        console.warn(`"${enumValue}" does not match any valid key in the Sector enum.`);
        return "";
      }
    })
    .filter((key) => key !== ""); // Filter out any empty strings resulting from unmatched enum values.
}

export function convertSectorValuesToStringArray(enumValues: Sector[] | undefined): string[] {
  if (!enumValues) return [];
  return enumValues
    .map((enumValue) => {
      // Find the key in the Sector enum that matches the current enum value.
      const key = Object.keys(Sector).find(
        (key) => Sector[key as keyof typeof Sector] === enumValue
      );
      if (key !== undefined) {
        return key;
      } else {
        console.warn(`"${enumValue}" does not match any valid key in the Sector enum.`);
        return "";
      }
    })
    .filter((key) => key !== ""); // Filter out any empty strings resulting from unmatched enum values.
}

export function convertStringArrayToSectorValues(strings: string[] | undefined): Sector[] {
  if (!strings) return [];
  return strings.reduce((acc: Sector[], str) => {
    const enumValue = Sector[str as keyof typeof Sector];
    if (enumValue !== undefined) {
      acc.push(enumValue);
    } else {
      console.warn(`"${str}" is not a valid Sector enum value.`);
    }
    return acc;
  }, []);
}

export function convertStringArrayToGeographyValues(strings: string[] | undefined): Geography[] {
  if (!strings) return [];
  return strings.reduce((acc: Geography[], str) => {
    const enumValue = Geography[str as keyof typeof Geography];
    if (enumValue !== undefined) {
      acc.push(enumValue);
    } else {
      console.warn(`"${str}" is not a valid Sector enum value.`);
    }
    return acc;
  }, []);
}

export function convertStringToRoundRoleValue(string: string | undefined): RoundRole | undefined {
  if (!string) return undefined;
  const enumValue = RoundRole[string as keyof typeof RoundRole];
  if (enumValue !== undefined) {
    return enumValue;
  } else {
    console.warn(`"${string}" is not a valid RoundRole enum value.`);
    return undefined;
  }
}

export function convertStringToLeadStatusValue(string: string): LeadStatus {
  const enumValue = LeadStatus[string as keyof typeof LeadStatus];
  if (enumValue !== undefined) {
    return enumValue;
  } else {
    throw Error(`"${string}" is not a valid LeadStatus enum value.`);
  }
}

export function extractDomain(url: string): string | null {
  // Regular expression to match the domain in a URL
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/\?#]+)/i;
  const match = url.match(regex);
  console.log(match);
  return match ? match[1] : null;
}

export function sanitizeLinkedInUrl(linkedInUrl: string): string {
  const regex = /linkedin\.com\/in\/([^\/]+)\/?/;
  const username = linkedInUrl.match(regex);
  if (!username) {
    throw new Error("Invalid LinkedIn URL");
  }
  return `https://www.linkedin.com/in/${username[1]}/`;
}

export function formatNumberWithCommas(input: string): string {
  if (!/^\d+$/.test(input)) {
    throw new Error("Input must be a string of digits only.");
  }

  return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
